import React, { useState, useEffect, useRef } from 'react';
import UserSection from '../../Services/User/UserLogin';
import { FaSearch } from 'react-icons/fa';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';

const Autocomplete = ({ suggestions = [], onSelected }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const inputRef = useRef(null);

    // Function to handle click outside of the input
    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle input change and show suggestions
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setShowSuggestions(true);
    };

    // Function to handle when a suggestion is clicked
    const handleSuggestionClick = () => {
        // setInputValue(suggestion.product_desc);
        // setShowSuggestions(false);
        console.log("autosearch", selectedItems);
        onSelected(selectedItems);
        setSelectedItems([]);
        setShowSuggestions(false);
    };

    // Function to handle key up event
    const handleKeyUp = async (e) => {
        try {
            const response = await UserSection.byProduct(e.target.value);
            setFilteredSuggestions(response.data);  // Set filtered suggestions
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Handle checkbox selection
    const handleCheckboxChange = (suggestion) => {
        setSelectedItems((prevSelected) => {
            const isAlreadySelected = prevSelected.some(item => item.sku_code === suggestion.sku_code);
            if (isAlreadySelected) {
                // Remove from selected items if already selected
                return prevSelected.filter(item => item.sku_code !== suggestion.sku_code);
            } else {
                // Add to selected items if not selected
                return [...prevSelected, suggestion];
            }
        });
    };

    // Handle the action when the button is clicked
    const handleActionClick = () => {
        if (selectedItems.length > 0) {
            console.log("Selected items for action:", selectedItems);
            // Here, you could perform any action with the selected items, like submitting or further processing
            alert('Selected items: ' + selectedItems.map(item => item.product_desc).join(', '));
        } else {
            alert('No items selected!');
        }
    };

    return (
        <div ref={inputRef} className="w-[25rem]">
            <div className="relative">
                <input
                    type="search"
                    value={inputValue}
                    onKeyUp={handleKeyUp} // Trigger on key up
                    onChange={handleInputChange} // Handle input changes
                    placeholder="Search to Add New SKU"
                    className="w-full p-3 pl-5 border border-gray-500 rounded-md text-gray-700 placeholder-gray-400 focus:outline-none focus:border-blue-500 transition duration-150"
                />
                {inputValue.length === 0 && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <FaSearch className="text-gray-900" />
                    </span>
                )}

                {showSuggestions && (
                    <ul className="absolute w-full bg-white border border-gray-200 rounded-md shadow-md mt-1 z-10">
                        {filteredSuggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                className="p-2 flex items-center justify-between hover:bg-blue-100 cursor-pointer text-sm text-gray-700 transition-colors duration-150"
                                // onClick={() => handleSuggestionClick(suggestion)} // Handle suggestion click
                            >
                                <div className="flex items-center">
                                    {/* Use CheckBoxOutlineBlank for unchecked state and CheckBox for checked */}
                                    {selectedItems.some(item => item.sku_code === suggestion.sku_code) ? (
                                        <CheckBox
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering handleSuggestionClick
                                                handleCheckboxChange(suggestion); // Handle checkbox selection/deselection
                                            }}
                                            className="text-blue-500"
                                        />
                                    ) : (
                                        <CheckBoxOutlineBlank
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering handleSuggestionClick
                                                handleCheckboxChange(suggestion); // Handle checkbox selection/deselection
                                            }}
                                            className="text-gray-500"
                                        />
                                    )}
                                    {suggestion.product_desc} / {suggestion.sku_code}
                                </div>
                            </li>
                        ))}
                        {/* Button added here after the list items */}
                        <li className="p-2">
                            <button
                                onClick={handleSuggestionClick}
                                className="w-full px-4 py-2 text-white bg-black  rounded-md hover:bg-white hover:text-black  border border-white hover:border-black transition duration-150"
                            >
                                Add Selected Items
                            </button>
                        </li>
                    </ul>
                )}

            </div>


        </div>
    );
};

export default Autocomplete;
