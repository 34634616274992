import UserSection from "../../Services/User/UserLogin";
import { useState } from "react";
import axios from "axios";
import { handleApiError } from "../../Helpers/handleApiError ";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Carousel from "../Carousel";
import Swal from "sweetalert2";
const images = ['https://grohelogin.ruminateitsolution.com/3N.jpg', 'https://grohelogin.ruminateitsolution.com/6N.jpg', 'https://grohelogin.ruminateitsolution.com/4N.jpg'];

function ResetPassword() {
    const { token } = useParams();
    const { page } = useParams();
    const [password, setPassword] = useState();
    const [confirmed, setconfirmed] = useState();
    const [usetToken, setUserToken] = useState();
    const [email, setEmail] = useState();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)
        const param2 = urlParams.get('email');
        setUserToken(token);
        setEmail(param2);
        console.log(param2);
    }, []);
    const updatePasword = async (e) => {
        e.preventDefault();
        try {
            const user = localStorage.getItem("user");
            const headers = {
                'Content-Type': 'application/json', // Example header
                Authorization: 'Bearer ' + user, // Example Authorization header
            };
            const data = {
                email,
                token,
                password,
                confirmed,
            };
            const response = await axios.post('https://quatationsapis.groheapp.in/public/api/reset-password', data, { headers });

            // if (response.status) {
            //     Swal.fire({
            //         icon: 'success',
            //         title: 'success',
            //         text: response.data.message,
            //         showConfirmButton: true, // Display the OK button
            //         confirmButtonText: 'OK', // Customize the text on the OK button
            //         timer: 200000, // Automatically close after 3 seconds
            //       });
            //     window.location.href = '/login';
            // } else {
            //     console.log(response);
            // }
            Swal.fire({
                icon: 'success',
                title: 'success',
                text: 'Password Update successfully',
                timer: 30000, // Automatically close after 3 seconds
                showConfirmButton: true,
                confirmButtonText: 'OK',
            }).then((result) => {

                if (result.isConfirmed) {
                    window.location.href = '/';
                }
            });
        } catch (error) {
            // console.log(error.response.data.message);
            handleApiError(error, 'Failed to fetch data. Please try again later.');

        }

    }
    return (
        <>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="fixed -z-50 left-0 bottom-0 top-0">
                    <Carousel images={images} />
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6 change_password" action="#" method="POST">

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 ">
                                    Password
                                </label>

                            </div>
                            <div className="mt-2">
                                <input
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 ">
                                    Confirmed  Password
                                </label>

                            </div>
                            <div className="mt-2">
                                <input
                                    value={confirmed}
                                    onChange={e => setconfirmed(e.target.value)}
                                    id="confirmed"
                                    name="confirmed"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={updatePasword}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default ResetPassword;