import Request from "../../Helpers/Request"
const userLogin = async (data) => {
    return await Request("login", data)
}
const forGotPassword = async (data) => {
    return await Request("forgot-password/", data,);
}
const updatePassword = async (data) => {
    return await Request("reset-password/", data);
}
const changePassword = async (data) => {
    return await Request("change-password/", data);
}
const addClient = async (data) => {
    return await Request("client/add", data);
}
const updateClient = async (data) => {
    return await Request("client/update", data);
}
const addClient_bulk = async (data) => {
    return await Request("client/add_bulk", data);
}
const clientLogin = async (data) => {
    return await Request("client/login/" + data, '', 'get');
}

const allClients = async () => {
    return await Request("client", '', 'get');
}
const productBySkeu = async (data) => {
    return await Request("project/sku/" + data, '', 'get');
}
const allselection = async () => {
    return await Request("project/selection", '', 'get');
}
const saveProductData = async (data) => {
    return await Request("project/store", data);
}
const updateProductData = async (data) => {
    return await Request("project/summary/update", data);
}
const projectData = async () => {
    return await Request("project/summary", '', 'get');
}
const editProject = async (data) => {
    if (data) {
        return await Request("project/summary/edit/" + data.projectId + '/' + data.selectionId, '', 'get');
    }
}
const updateProject = async (data) => {
    return await Request("project/summary/update", data);
}
const viewProjectById = async (data) => {
    return await Request("project/view/" + data, '', 'get');
}
const downloadExcelById = async (data) => {
    return await Request("project/download/" + data, '', 'get');
}
const downloadExcel = async () => {
    return await Request("project/downlaod/excel/", '', 'get');
}
const downloadsummary = async (data) => {
    return await Request("project/downloadSummary/" + data, '', 'get');
}
const saveSuggestion = async (data) => {
    return await Request("project/help_desk", data);
}
const getprofile = async (data) => {
    return await Request("profile", '', 'get');
}
const helpDeskList = async () => {
    return await Request("project/help/desk/list", '', 'get');
}
const allProduct = async () => {
    return await Request("project", '', 'get');
}
const byProduct = async (data) => {
    if (data) {
        return await Request("project/product/" + data, '', 'get');
    }
}
const excelformate = async () => {
    return await Request("project/downloadformate", '', 'get');
}
const excelImport = async (data) => {
    return await Request("project/import-csv", data);
}
const UserSection = {
    userLogin,
    addClient,
    updateClient,
    forGotPassword,
    updatePassword,
    changePassword,
    clientLogin,
    allClients,
    productBySkeu,
    allselection,
    saveProductData,
    projectData,
    editProject,
    updateProject,
    viewProjectById,
    downloadExcel,
    saveSuggestion,
    helpDeskList,
    getprofile,
    updateProductData,
    byProduct,
    downloadsummary,
    excelformate,
    excelImport,
    addClient_bulk,
    downloadExcelById,
    allProduct,
}
export default UserSection