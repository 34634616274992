import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../Services/UserList";
import Swal from "sweetalert2";
import MainLayout from "./admin/MainLayout";
import Loader from "./utils/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
  TextField,
  Box,
} from "@mui/material";

export default function AdminUserList() {
  const [users, setUsers] = useState([]); // All users fetched from API
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Track current page (0-indexed)
  const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page

  useEffect(() => {
    userlistapi();
  }, []);

  const userlistapi = async () => {
    try {
      setLoading(true);
      const response = await UserService.UserList();
      setUsers(response.data.data); // Set all users from API
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000, // Automatically close after 3 seconds
      });
      setLoading(false);
    }
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtered users based on search query
  const filteredUsers = users.filter((user) =>
    `${user.name} ${user.last_name} ${user.email} ${user.emp_code}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Paginate filtered users
  const currentUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <MainLayout>
      {loading && <Loader />}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 3,
          padding: 2,
        }}
      >
        <TextField
          label="Search User"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "100%",
            maxWidth: 400,
            // borderRadius: '50px',
            backgroundColor: "#f0f0f0",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          }}
        />
      </Box>
      <Paper elevation={3} style={{ margin: "1rem", padding: "1rem" }}>
        {/* Search Bar */}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  SL No.
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  First Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Last Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Email
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Emp Code
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  City
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  State
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Status
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentUsers.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.last_name}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.emp_code}</TableCell>
                  <TableCell align="center">{item.city}</TableCell>
                  <TableCell align="center">{item.state}</TableCell>
                  <TableCell align="center">
                    {item.is_active === 1 ? (
                      <span style={{ color: "green" }}>Active</span>
                    ) : (
                      <span style={{ color: "red" }}>Deactive</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/admin/edit/${item.id}`}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            marginTop: "1rem",
          }}
          rowsPerPageOptions={[10, 20, 50, 100, users.length]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </MainLayout>
  );
}
