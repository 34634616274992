import React, { useState, useEffect } from "react";
import SidebarNavigation from "../SidebarNavigation";
import { handleApiError } from "../../Helpers/handleApiError ";
import UserSection from "../../Services/User/UserLogin";
import Swal from "sweetalert2";
import UserTopbar from "../UserTopbar";
import { useRef } from "react";
import Lottie from "lottie-react";
import animationData from "../../animations/loader_animation0.json";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal, Button, TextField, Tabs, Tab } from "@mui/material";
import { AddCircleOutline, Delete, Refresh, Save } from "@mui/icons-material"; // Import MUI icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import Autocomplete from "./Autocomplete";
import { get } from "svelte/store";
import * as XLSX from "xlsx";
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Select, { components } from "react-select";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import Loader from "../utils/Loader";
import { useLocation } from "react-router-dom";
import { FiTrash2, MdDelete, AiOutlineDelete } from "react-icons";
import { FaTrash } from "react-icons/fa";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DriveFolderUploadSharpIcon from "@mui/icons-material/DriveFolderUploadSharp";
import { useOutletContext } from "react-router-dom";
import BallotIcon from "@mui/icons-material/Ballot";
import NotificationDrawer from "../NotificationDrawer";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import SyncIcon from "@mui/icons-material/Sync";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SmartSearchButton from "../utils/SmartSearchButton";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

const UserProjects = () => {
  const [currentFilter, setCurrentFilter] = useState(true);
  const [selectedDate, setSelectedDate] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [expandMore, setExpandMore] = useState(false);
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const fileInputRef = useRef(null);
  const { isSidebarOpen } = useOutletContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientsId = params.get("client_id");
  const { projectId } = useParams();
  const [client, setClient] = useState();
  const navigate = useNavigate();
  const [removedIds, setRemovedIds] = useState([]);
  const [selectionId, setSelectionID] = useState(1);
  const [selectionClient, setselectionClient] = useState();
  const [secName, setSecName] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows_id, setRowsId] = useState(1);
  const [files, setFiles] = useState(null);
  const [upload, setupload] = useState(false);
  const [error, setError] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [tableData, setTableData] = useState([
    {
      id: rows_id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      collection: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      total: "",
      discount: "",
      selection: selectionId,
      active: true,
      selection_name: null,
      client_id: null,
      type: "add",
      check_gst: "",
      unit: 0,
    },
  ]);
  // const [sortOrder, setSortOrder] = useState("asc");
  // const sortTableData = (property) => {
  //   const sortedData = [...tableData].sort((a, b) => {
  //     if (sortOrder === "asc") {
  //       return a[property] > b[property] ? 1 : -1;
  //     } else {
  //       return a[property] < b[property] ? 1 : -1;
  //     }
  //   });
  //   setTableData(sortedData);
  //   setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  // };

  // Function for sorting in ascending order
  // const sortTableDataAsc = (property) => {
  //   const sortedData = [...tableData].sort((a, b) => {
  //     return a[property] > b[property] ? 1 : -1;
  //   });
  //   setTableData(sortedData);
  // };

  // // Function for sorting in descending order
  // const sortTableDataDesc = (property) => {
  //   const sortedData = [...tableData].sort((a, b) => {
  //     return a[property] < b[property] ? 1 : -1;
  //   });
  //   setTableData(sortedData);
  // };

  // const CustomOption = (props) => {
  //   const { data, innerRef, innerProps } = props;
  //   const isSelected = selectedSpare.includes(data.value);

  //   return (
  //     <div
  //       ref={innerRef}
  //       {...innerProps}
  //       style={{ display: "flex", alignItems: "center", padding: "0.5rem" }}
  //     >
  //       <input
  //         type="checkbox"
  //         checked={isSelected}
  //         onChange={() => null}
  //         style={{ marginRight: "0.5rem" }}
  //       />
  //       <label>{data.label}</label>
  //     </div>
  //   );
  // };

  const sortTableDataAsc = (property) => {
    const sortedData = [...tableData].sort((a, b) => {
      const valueA = Number(a[property]) || 0;
      const valueB = Number(b[property]) || 0;

      return valueA - valueB; // Ascending order
    });
    setTableData(sortedData);
  };

  // Function for sorting in descending order
  const sortTableDataDesc = (property) => {
    const sortedData = [...tableData].sort((a, b) => {
      const valueA = Number(a[property]) || 0;
      const valueB = Number(b[property]) || 0;

      return valueB - valueA; // Descending order
    });
    setTableData(sortedData);
  };

  const handleDebounce = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  };

  const [clientId, setclientId] = useState();
  const [data, setData] = useState("");
  const [selection, setSelection] = useState();
  const [selectedOption, setSelectedOption] = useState("Select Client");
  const [errorMessages, setErrorMessages] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [amountType, setAmountType] = useState("price");
  const [discountPer, setDiscountPer] = useState();
  const [sub_Total, setSub_Total] = useState(0);
  const [gst, setGst] = useState(true);
  const [gstt, setGstt] = useState(1);
  const [name, setName] = useState();
  const [excludeGst, setexcludeGst] = useState(false);
  const [includeGst, setincludeGst] = useState(false);
  const [searchqty, setsearchqty] = useState();
  const [percFilter, setPercFilter] = useState(null);
  const [formData, setFormData] = useState({
    client_name: "",
    site_add: "",
    afd: "",
    add_to: "",
    username: "",
  });
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [items, setItems] = useState([
    {
      id: 1,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      unit: "",
      total: "",
      discount: "",
      selection: "",
    },
  ]);
  const dropdownRef = useRef(null);
  const clientRef = useRef(null);
  const selectionNameref = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    ClientList();
    Selection();
    setselectionClient(clientsId);
    const clientIdFromStorage = localStorage.getItem("client_id");
    if (clientIdFromStorage) {
      setselectionClient(clientIdFromStorage);
      const timer = setTimeout(() => {
        localStorage.removeItem("client_id");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  // Define the getLargestId function
  const getLargestId = (indexId) => {
    // Example logic: Get the largest ID from the existing tableData
    const maxId = Math.max(...tableData.map((item) => item.id), 0);
    const indexsum = indexId ? indexId : 0;
    return maxId + indexsum + 1; // Return the next largest ID
  };

  const handleSelected = async (selectedArray) => {
    let total_slot = 0;
    const newSelectionId = selectionId;

    // Calculate the total number of selected items for the given selection
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].selection === newSelectionId) {
        total_slot += 1;
      }
    }

    // Check if the total slot exceeds the limit
    if (total_slot >= 300) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Rows Limit reached = 300",
      });
      return false;
    }

    // Filter out items with empty SKU codes
    const filteredItems = tableData.filter((item) => item.sku_code !== "");

    let IdIndex = 1;
    selectedArray.forEach((selected) => {
      let hasgst;
      let unit_price = parseFloat(selected.mrp * 100) / (100 + selected.gst);
      let unit_mrp = unit_price;
      let ref_total = selected.mrp;

      if (gst == 1) {
        hasgst = selected.gst;
        ref_total = parseFloat(selected.mrp);
      } else {
        hasgst = 0;
        ref_total = parseFloat(unit_mrp);
      }

      // Get the largest ID (Ensure this function is properly defined)
      // const getLargestId = getLargestId();  // Now it's safe to use
      const resdata = {
        id: getLargestId(IdIndex),
        check: false,
        sku_code: selected.sku_code,
        collection: selected.collection,
        brand: selected.brand,
        product_desc: selected.product_desc,
        finish: selected.finish,
        image: selected.image,
        quantity: "",
        area: "null",
        mrp: selected.mrp,
        discount_price: "",
        discount_per: "",
        gst: hasgst,
        total: ref_total, // Set total correctly
        Spare: selected.spare_fg,
        Group: selected.product_typ_group,
        discount: 0,
        selection: selectionId,
        active: true,
        selection_name: secName, // Ensure secName is valid
        check_gst: selected.gst,
        unit: unit_mrp,
      };
      IdIndex += 1;
      // Add the new data to the filtered items array
      filteredItems.push(resdata);
    });

    // Update the state with the new table data
    setTableData(filteredItems);
    setRowsId(rows_id + selectedArray.length); // Update the row ID accordingly
  };

  const removeRowsWithEmptySkuCode = async () => {
    // Filter out rows with empty sku_code
    const updatedTableData = tableData.filter((row) => {
      const isEmptySkuCode = row.sku_code.trim() === ""; // Check if sku_code is empty or contains only whitespace
      if (isEmptySkuCode) {
      }
      return !isEmptySkuCode; // Keep rows that do not have an empty sku_code
    });

    // Update the state with the filtered tableData
    setTableData([]);
    setTableData((prevTableData) => updatedTableData);
  };

  // Call the function whenever you need to remove rows with empty sku_code

  //edit project data
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await UserSection.editProject(projectId);
  //         const newData = [];
  //         setselectionClient(response.data[0].client_id);
  //         setSecName(response.data[0].secname);
  //         response.data.forEach((item, index) => {
  //           const productData = item.product_data[index];
  //           newData.push({
  //             id: item.project_id,
  //             check: false,
  //             sku_code: item.sku_code,
  //             brand: productData.product.brand,
  //             product_desc: productData.product.product_desc,
  //             finish: productData.product.finish, // Assuming 'finish' is directly accessible from product_data
  //             image: productData.product.image, // You can set the default value or access it if available
  //             quantity: item.qty,
  //             area: item.area,
  //             mrp: productData.product.mrp,
  //             discount_price: productData.disc_price,
  //             discount_per: productData.disc_per,
  //             gst: productData.gst,
  //             total: productData.total, // You can set the default value or access it if available
  //             discount: "", // You can set the default value or access it if available
  //             selection: item.section_id,
  //             active: item.section_id == selectionId ? true : false,
  //             selection_name: item.secname,
  //             client_id: item.client_id,
  //             type: "update",
  //           });
  //         });
  //         setTableData([]);
  //         setTableData(newData);

  //       } catch (error) {
  //         // Handle errors if any
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     fetchData(); // Call the inner async function immediately
  //   }, [projectId, upload]);
  const [previousSelectionIds, setPreviousSelectionIds] = useState([]);
  const [noOfSelection, setNoOfSelection] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const itemToUpdate = tableData.find(
        (item) => item.selection == selectionId && item.sku_code != ""
      );

      try {
        setLoading(true);
        const response = await UserSection.editProject({
          projectId,
          selectionId,
        });
        const newData = [];
        setselectionClient(response.data.client_id);
        if (selectionId === 1) setSelectedDate(response.data.valid_date);

        const uniqueSelections = [
          ...new Set(
            response.data.section_count.map((item) => item.section_id)
          ),
        ];

        setNoOfSelection(uniqueSelections);

        Object.keys(response.data.section).forEach((sectionKey) => {
          let fixedGst = response.data.section[selectionId][0].gst;
          let totalGst = fixedGst ? 1 : 0;

          setGst(totalGst);
          setAmountType(response.data.section[selectionId][0].type);
          // setSelectedDate(response.data.valid_date);
          setGstt(totalGst);

          response.data.section[sectionKey].forEach((item) => {
            // setGst(item.gst)
            const productData = item.product_attr; // Use 'product' directly from the response
            newData.push({
              id: item.id, // Assuming 'project_details_id' is the unique identifier
              check: false,
              sku_code: item.sku_code,
              brand: productData.brand,
              product_desc: productData.product_desc,
              finish: productData.finish || "", // Default to empty string if not available
              image: productData.image || "", // Default to empty string or image path if not available
              quantity: item.qty,
              area: item.area,
              collection: item.collection,
              mrp: productData.mrp,
              discount_price: item.disc_price,
              discount_per: item.disc_per,
              // gst: gst == 1 ? productData.gst : 0,
              gst: totalGst ? item.product_attr.gst : 0,
              total: item.total || 0, // Assuming 'totals' is the total price you want
              discount: "", // Default value or calculation can be added here
              selection: item.section_id,
              active: item.section_id === selectionId, // Compare with selectionId to set active
              selection_name: item.section_name,
              check_gst: productData.gst,
              active: true,
              type: "update",
              Spare: productData.spare_fg,
              Group: productData.product_typ_group,
              unit: item.unit,
            });
          });
        });

        const selectedItem = newData.find(
          (item) => item.selection == selectionId
        );

        const newSecName = selectedItem
          ? selectedItem.selection_name || ""
          : "";
        setSecName(newSecName);

        const mappedNewData = newData.map((item) => ({
          id: item.id,
          check: item.check,
          sku_code: item.sku_code,
          brand: item.brand,
          product_desc: item.product_desc,
          finish: item.finish,
          image: item.image,
          quantity: item.quantity,
          area: item.area,
          mrp: item.mrp,
          collection: item.collection,
          discount_price: item.discount_price,
          discount_per: item.discount_per,
          gst: gst == 1 ? item.gst : 0,
          total: item.total,
          discount: item.discount,
          selection: item.selection,
          active: item.active,
          selection_name: item.selection_name,
          client_id: null,
          type: item.type,
          check_gst: item.check_gst,
          Spare: item.Spare,
          Group: item.Group,
          unit: item.unit,
        }));

        // setTableData((prev) => [...prev, ...mappedNewData]);

        setTableData((prev) => {
          const updatedData = [...prev, ...mappedNewData];
          return updatedData.filter(
            (item) => item.sku_code !== "" && item.sku_code !== null
          );
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [previousSelectionIds]);

  useEffect(() => {
    if (selectionId != 1) {
      if (!previousSelectionIds.includes(selectionId)) {
        setPreviousSelectionIds((prev) => [...prev, selectionId]);
      }
    }
  }, [selectionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UserSection.addClient(formData);
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "client",
          text: "Client added  successfully",
          timer: 3000, // Automatically close after 3 seconds
        });
        setFormData({
          client_name: "",
          site_add: "",
          afd: "",
          add_to: "",
          username: "",
        });
        ClientList();
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  // Login with id

  const viewclent = async (e) => {
    e.preventDefault();
    if (clientId != 0) {
      try {
        const response = await UserSection.clientLogin(clientId);
        if (response.status) {
          setData(response.data);
        }
      } catch (error) {
        handleApiError(error, "Failed to fetch data. Please try again later.");
      }
    } else {
      return 0;
    }
  };
  const ClientList = async () => {
    // e.preventDefault()
    try {
      const response = await UserSection.allClients();
      if (response.status) {
        setClient(response.data);
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  const handleSelectChange = (option) => {
    // Check if the option is valid
    if (option) {
      setselectionClient(option.value); // Set the selected client ID
    }
  };

  const options = client
    ? client.map((item) => ({
        value: item.id,
        label: `${item.client_name.toUpperCase()} - ${item.id}`,
      }))
    : [];

  const handlesku = async (e, id) => {
    const updatedItems = [...tableData];

    // Find the index of the object with the matching id
    const index = updatedItems.findIndex((item) => item.id === id);

    const resdata = {
      id: updatedItems[index].id,
      check: false,
      sku_code: e.target.value,
      brand: "",
      product_desc: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      total: 0,
      discount: 0,
      selection: selectionId,
      active: true,
      selection_name: secName,
    };
    // updatedItems = { ...updatedItems[index], resdata };
    updatedItems[index] = resdata;
    setTableData(updatedItems);
  };
  const handleKeyUp = async (e, id) => {
    const updatedItems = [...tableData];
    const index = updatedItems.length;
    try {
      // alert(e.target.value);
      if (e.target.value != 0) {
        const updatedItems = [...tableData];

        // Find the index of the object with the matching id
        const index = updatedItems.findIndex((item) => item.id === id);

        const response = await UserSection.productBySkeu(e.target.value);
        if (response.status) {
          // Create a copy of the array

          // Update the object's properties
          if (index !== -1) {
            let hasgst;
            let unit_price =
              parseFloat(response.data.mrp * 100) /
              parseFloat(100 + response.data.gst);
            let unit_mrp = unit_price;
            let ref_total = response.data.mrp;
            if (gst == 1) {
              hasgst = response.data.gst;
              // let ref_total = parseFloat((hasgst * response.data.mrp) / 100);

              ref_total = parseFloat(response.data.mrp);
            } else {
              hasgst = 0;
              ref_total = parseFloat(unit_mrp);
            }
            let price_gst = 0;

            const getLartgest_Id = getLartgestId();
            const resdata = {
              id: getLartgest_Id,
              check: false,
              sku_code: response.data.sku_code,
              brand: response.data.brand,
              product_desc: response.data.product_desc,
              finish: response.data.finish,
              image: response.data.image,
              quantity: "",
              area: "null",
              mrp: response.data.mrp,
              discount_price: "",
              collection: response.data.collection,
              discount_per: "",
              gst: gst == 1 ? response.data.gst : 0,
              total: formatNumber(ref_total),
              discount: 0,
              selection: selectionId,
              active: true,
              selection_name: secName,
              check_gst: response.data.gst,
              Spare: response.data.spare_fg,
              Group: response.data.product_typ_group,
              unit: unit_mrp,
            };

            updatedItems[index] = resdata;
            setTableData(updatedItems);

            //    setRowsId(rows_id + 1);
          }
        }
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  const Selection = async () => {
    try {
      const response = await UserSection.allselection();
      if (response.status) {
        setSelection(response.data);
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  const saveProjectData = async (e) => {
    let dataStatsu = false;
    dataStatsu = await errorValidation();
    if (dataStatsu == true) {
      if (!projectId) {
        Swal.fire({
          title: "Do you want to continue?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Yes, continue",
          cancelButtonText: "No, cancel",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            saveConfimed();
            setLoading(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            setLoading(false);
          }
        });
      } else {
        Swal.fire({
          title: "Do you want to Save?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Save New",
          cancelButtonText: "Update Existing",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            saveConfimed();
            setLoading(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            updateConfimed();
            setLoading(false);
          }
        });
      }
    } else {
      return 0;
    }
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [totalStep, setTotalStep] = useState(0);
  const [progress, setProgress] = useState(0);

  const generateProjectId = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const projectId = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return projectId;
  };

  const saveConfimed = async () => {
    const PID = generateProjectId();
    const client_name_for_PID = client
      .find((client) => client.id === selectionClient)
      .client_name.toUpperCase();
    try {
      setLoading(true);
      setProgress(true);
      const filteredItems = tableData
        .filter((item) => item.sku_code !== "")
        .map(({ image, ...rest }) => rest);
      const groupedBySelection = filteredItems.reduce((acc, item) => {
        acc[item.selection] = acc[item.selection] || [];
        acc[item.selection].push(item);
        return acc;
      }, {});

      const selectionOrder = Object.keys(groupedBySelection).sort(
        (a, b) => a - b
      );
      setTotalStep(selectionOrder.length);

      for (const selection of selectionOrder) {
        const itemsToSend = groupedBySelection[selection];

        const mergedState = {
          tableData: itemsToSend,
          selectionId,
          selectionClient,
          amountType,
          selectedDate,
          quatationId: `P_${client_name_for_PID}_${PID}`,
        };

        const response = await UserSection.saveProductData(mergedState);

        if (!response) {
          throw new Error(`Failed to save data for selection: ${selection}`);
        }

        setCurrentStep((prevStep) => prevStep + 1);
      }

      Swal.fire({
        title: "Success",
        icon: "success",
        html: `
           <div>
             <label>Project added successfuly</label> </br>
             <label>Project ID:</label>
             <span>P_${client_name_for_PID}_${PID}</span> </br>
             <label>Go To Summary To View Quotation</label>
            </div>
        `,
        showConfirmButton: true,
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          // removeState();
          setClient("");
          setselectionClient("");
          setSecName("");
          window.location.href = "/user/projects";
        }
      });
    } catch (error) {
      handleApiError(error, "Failed to process data. Please try again later.");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setProgress(false);
      }, 2000);
    }
  };

  const updateConfimed = async () => {
    try {
      const filteredItems = [...tableData];
      const updatedItems = filteredItems.filter((item) => item.sku_code !== "");

      await setTableData(updatedItems);
      setLoading(true);
      const mergedState = {
        tableData, // Spread the properties of state1
        selectionClient,
        projectId,
        removedIds,
        amountType,
        selectedDate,
      };
      const response = await UserSection.updateProductData(mergedState);
      if (response.status) {
        setLoading(false);
        Swal.fire({
          title: "success",
          icon: "success",
          html: `
                        <div>
                        <label>Project Update successfuly</label> </br>
                        <label>Project ID:</label>
                        <span>${response.project_id}</span>
                        </div>
                    `,

          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            removeState();
            setClient("");
            setselectionClient("");
            setSecName("");
            setLoading(false);

            // document.getElementById('clientId').value = '';
            // document.getElementById('sku_code');
            window.location.href = "/user/projects";
          }
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  const errorValidation = async () => {
    let updatedItems = [...tableData];
    let errorMessages = []; // Array to collect error messages

    if (selectionClient) {
      // Create a map to track selection names for each selection
      const selectionNamesMap = {};
      if (!projectId) {
        if (!selectedDate) {
          Swal.fire({
            icon: "warning",
            text: "Valid Date Required To Proceed",
            confirmButtonText: "OK",
          });
          return false;
        }
      }

      // Populate the selectionNamesMap with selection names
      updatedItems.forEach((item) => {
        if (item.sku_code !== "") {
          const selectionId = item.selection;
          const selectionName = item.selection_name;

          // If the selection ID is not in the map, add it
          if (!selectionNamesMap[selectionId]) {
            selectionNamesMap[selectionId] = selectionName;
          } else if (selectionNamesMap[selectionId] !== selectionName) {
            // If there's a mismatch in selection names for the same selection ID
            errorMessages.push({
              message: `Selection name mismatch for SKU: ${item.sku_code} in Selection: ${selectionId}. Expected: ${selectionNamesMap[selectionId]}, Found: ${selectionName}`,
              sku_code: item.id, //previously using sku code  so dont confuse with it
              selection: selectionId,
            });
          }
        }
      });

      // Check if selection names are provided for each selection
      for (const [selectionId, selectionName] of Object.entries(
        selectionNamesMap
      )) {
        if (!selectionName) {
          errorMessages.push({
            message: `Selection name is required for Selection: ${selectionId}`,
            sku_code: "", //previously using sku code  so dont confuse with it
            selection: selectionId,
          });
        }
      }

      for (let i = 0; i < updatedItems.length; i++) {
        if (
          (updatedItems[i].sku_code !== "" &&
            updatedItems[i].selection === "") ||
          updatedItems[i].selection === null
        ) {
          errorMessages.push({
            message: `Selection required for SKU: ${updatedItems[i].sku_code} in Selection: ${updatedItems[i].selection}`,
            sku_code: updatedItems[i].id, //previously using sku code  so dont confuse with it
            selection: updatedItems[i].selection,
          });
        }

        if (
          updatedItems[i].sku_code !== "" &&
          updatedItems[i].quantity === ""
        ) {
          errorMessages.push({
            message: `Quantity required for SKU: ${updatedItems[i].sku_code} in Selection: ${updatedItems[i].selection}`,
            sku_code: updatedItems[i].id, //previously using sku code  so dont confuse with it
            selection: updatedItems[i].selection,
          });
        }

        if (updatedItems[i].sku_code !== "" && updatedItems[i].mrp == 0) {
          errorMessages.push({
            message: `Check MRP (₹) of SKU: ${updatedItems[i].sku_code} in Selection: ${updatedItems[i].selection}`,
            sku_code: updatedItems[i].id, //previously using sku code  so dont confuse with it
            selection: updatedItems[i].selection,
          });
        }
        if (
          updatedItems[i].sku_code !== "" &&
          updatedItems[i].discount_price > updatedItems[i].mrp
        ) {
          errorMessages.push({
            message: `Discount price can't exceed MRP for SKU: ${updatedItems[i].sku_code} in Selection: ${updatedItems[i].selection}`,
            sku_code: updatedItems[i].id, //previously using sku code  so dont confuse with it
            selection: updatedItems[i].selection,
          });
        }

        if (
          updatedItems[i].sku_code !== "" &&
          updatedItems[i].discount_price === ""
        ) {
          // if (amountType !== "no") {
          //   errorMessages.push({
          //     message: `Discount price required for SKU: ${updatedItems[i].sku_code} in Selection: ${updatedItems[i].selection}`,
          //     sku_code: updatedItems[i].id, //previously using sku code  so dont confuse with it
          //     selection: updatedItems[i].selection,
          //   });
          // }
        }
      }

      // Example usage
      if (errorMessages.length > 0) {
        setErrorMessages(errorMessages);
        setDrawerOpen(true);
        return;
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Client Id Required",
        confirmButtonText: "OK",
      });
      return false;
    }
    return true;
  };

  const addRowNew = (newSelectionId = null) => {
    // Ensure the newSelectionId is set, fallback to selectionId if null
    if (newSelectionId === null) {
      newSelectionId = selectionId;
    }

    // Step 1: Count how many rows have the given selection
    let total_slot = tableData.filter(
      (row) => row.selection === newSelectionId
    ).length;

    // Step 2: Check if the row limit has been reached
    if (total_slot >= 300) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Rows Limit reached = 300",
      });
      return false; // Prevent adding a new row
    }

    // Step 3: Generate the new row ID based on your ID generation logic
    const getLartgest_Id = getLartgestId(); // Ensure this function returns the next available ID
    setRowsId(getLartgest_Id); // Update the rows_id state

    // Step 4: Create the new row object
    const newRow = {
      id: getLartgest_Id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discound_price: "",
      discound_per: "",
      gst: "",
      selection: newSelectionId,
      active: true,
      selection_name: "",
    };

    // Step 5: Update the table data (ensure state update is handled correctly)
    setTableData((prevTableData) => {
      // Use a functional update to ensure you're working with the most recent state
      return [...prevTableData, newRow]; // Append new row to the existing tableData
    });

    // Debugging output to verify the new row is added
  };

  const addRow = (newSelectionId = null) => {
    // e.preventDefault();
    var total_slot = 0;
    if (null == newSelectionId) {
      newSelectionId = selectionId;
    }
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].selection == newSelectionId) {
        total_slot += 1;
      }
    }
    if (total_slot >= 300) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Rows Limit riched = 300 ",
      });
      return false;
    }
    // var row_id = tableData.length + 1;
    // let r_id = rows_id + 1;
    const getLartgest_Id = getLartgestId();
    setRowsId(getLartgest_Id);

    const newRow = {
      id: getLartgest_Id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discound_price: "",
      discound_per: "",
      gst: "",
      selection: newSelectionId,
      active: true,
      selection_name: "",
    };
    setTableData([...tableData, newRow]);
  };

  const defaultItem = [
    {
      id: rows_id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "null",
      mrp: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      total: "",
      discount: "",
      selection: selectionId,
      active: true,
      selection_name: null,
      client_id: null,
      type: "add",
      check_gst: "",
    },
  ];

  const removeRow = (e) => {
    e.preventDefault();

    if (tableData.length === 0) {
      return;
    }
    const checkedItems = tableData.filter((item) => item.check);

    if (checkedItems.length > 0) {
      const idsToRemove = checkedItems.map((item) => item.id);

      setRemovedIds((prevRemovedIds) => [...prevRemovedIds, ...idsToRemove]);

      const updatedItems = tableData.filter((item) => !item.check);
      setTableData(updatedItems.length === 0 ? defaultItem : updatedItems);
    }
  };

  const removeRowOne = (e, id) => {
    e.preventDefault();
    let removeId = [];
    if (tableData.length === 1) {
      setTableData(defaultItem);
      return;
    }
    const itemToRemove = tableData.find((item) => item.id === id);
    if (itemToRemove) {
      setRemovedIds((prevRemovedIds) => [...prevRemovedIds, id]);
    }
    const updatedItems = tableData.filter((item) => item.id !== id);
    removeId.push(updatedItems[id]);
    setTableData(updatedItems);
  };

  const resetState = () => {
    removeState();
    setSelectionID(0);
    setSecName();
    setselectionClient();
    setSub_Total(0);
    tableData[0].sku_code = "";
    window.location.href = "/user/projects";
  };
  const removeState = () => {
    // tableData[0].sku_code = "";
    setTableData([
      {
        id: 1,
        check: false,
        sku_code: null,
        brand: "",
        brand_description: "",
        finish: "",
        image: "",
        quantity: "",
        area: "",
        mrp: "",
        discount_price: "",
        discount_per: "",
        gst: "",
        total: "",
        discount: "",
        selection: selectionId,
        active: true,
      },
    ]);
  };
  const handleCheckboxChange = (status, id) => {
    const updatedItems = [...tableData];

    const index = updatedItems.findIndex((item) => item.id === id);
    if (index !== -1) {
      updatedItems[index].check = !updatedItems[index].check;
      setTableData(updatedItems);
    } else {
      console.warn("Item with id", id, "not found!");
    }
  };

  const changeformdataqtyOld = (e, index) => {
    if (e.target.value >= 0) {
      const updatedItems = [...tableData];

      const all_total = sub_Total;
      // const index = updatedItems.findIndex(item => item.id === id);
      updatedItems[index].quantity = e.target.value;
      let roundTotal = parseFloat(updatedItems[index].mrp * e.target.value);

      let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
      roundTotal += gst;
      // const roundTotal = updatedItems[index].mrp * updatedItems[index].quantity * updatedItems[index].gst / 100;
      // roundTotal+=gst;
      updatedItems[index].total = parseFloat(
        roundTotal - updatedItems[index].discount
      );
      // updatedItems[index].total += updatedItems[index].total +gst;
      // all_total +  updatedItems[index].total;
      // updatedItems[index].total = updatedItems[index].total - updatedItems[index].discount;
      setSub_Total(all_total + updatedItems[index].total);
      setTableData(updatedItems);
      // updatePrice(index);
    } else {
      alert("1");
      const updatedItems = [...tableData];
      let roundTotal = parseFloat(updatedItems[index].mrp * 1);
      let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
      roundTotal += gst;

      updatedItems[index].total = roundTotal;
      setTableData(updatedItems);
      return 0;
    }
  };

  const changeformdataqty = (e, id) => {
    const newQuantity = e.target.value;
    if (newQuantity < 0) {
      alert("Quantity must be greater than or equal to 0.");
      return resetItemToDefault(id);
    }
    const index = tableData.findIndex((item) => item.id === id);

    if (index === -1) {
      console.error("Item not found with the provided ID");
      return; // Handle case where the item is not found
    }
    // Get the item to update
    const updatedItems = [...tableData];
    const item = updatedItems[index];

    let cal_mrp = gst == 1 ? item.mrp : item.unit;

    item.quantity = newQuantity;

    item.total = calculateItemTotal(
      cal_mrp,
      newQuantity,
      item.discount_price,
      item.discount
    );

    // Update tableData state and recalculate the subtotal
    setTableData(updatedItems);
    updateSubtotal(updatedItems);
  };

  // Utility function to calculate item total including GST and discount
  const calculateItemTotal = (mrp, quantity, discount_price, discount) => {
    let baseTotal = mrp * quantity;
    // let gstAmount = (baseTotal * gst) / 100;
    let total = baseTotal - discount_price * quantity;

    return parseFloat(formatNumber(total)); // Round to 2 decimal places for accuracy
  };

  // Utility function to reset an item when quantity is invalid
  const resetItemToDefault = (id) => {
    // Find the index of the item using the provided id
    const index = tableData.findIndex((item) => item.id === id);

    if (index === -1) {
      return; // Handle case where the item is not found
    }

    const updatedItems = [...tableData];
    const item = updatedItems[index];

    // Default quantity is 1 if invalid input is detected
    item.quantity = 1;
    item.total = calculateItemTotal(item.mrp, 1, item.gst, item.discount);

    // Update tableData state and recalculate the subtotal
    setTableData(updatedItems);
    updateSubtotal(updatedItems);
  };

  // Utility function to calculate the subtotal of all items
  const updateSubtotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.total, 0);
    // setSub_Total(parseFloat(total.toFixed(2))); // Round to 2 decimal places
  };

  const updatePrice = (index) => {
    const updatedItems = [...tableData];
    let price;
    let qty = updatedItems[index].quantity;
    let dicPrice = updatedItems[index].discount_price;
    let discPer = updatedItems[index].discount_per;
    let disOnQty = dicPrice * qty;
    let total;

    if (gst == "1") {
      price = parseFloat(updatedItems[index].mrp);
    } else {
      price = parseFloat(updatedItems[index].unit);
    }
    total = price * qty;
    total -= disOnQty;
    updatedItems[index].total = total;
  };

  const changeAmountType = (e, id) => {
    e.preventDefault();
    let value = e.target.value || "0";
    let dis = 0;
    const index = tableData.findIndex((item) => item.id === id);
    let updatedItems = [...tableData];
    // if (e.target.value) {
    if (value > -1) {
      if (amountType == "no") {
        return 0;
      } else if (amountType == "price") {
        if (gst == "1") {
          updatedItems[index].discount_price = e.target.value;
          let discount_mrp = e.target.value * updatedItems[index].quantity;
          updatedItems[index].total = updatedItems[index].total - discount_mrp;
          // set discount price
          updatedItems[index].discount_per =
            parseFloat(e.target.value * 100) / updatedItems[index].mrp;
        } else {
          updatedItems[index].discount_price = e.target.value;
          let discount_mrp = e.target.value * updatedItems[index].quantity;
          updatedItems[index].total = updatedItems[index].total - discount_mrp;
          // set discount price
          updatedItems[index].discount_per =
            parseFloat(e.target.value * 100) / updatedItems[index].unit;
        }
        setTableData(updatedItems);
      } else {
        if (gst == "1") {
          let total = updatedItems[index].mrp * updatedItems[index].quantity;
          updatedItems[index].discount_per = e.target.value;
          let discountPrice = (updatedItems[index].mrp * e.target.value) / 100;
          updatedItems[index].discount_price = discountPrice;
          updatedItems[index].total = total;
          let finalDicount = discountPrice * updatedItems[index].quantity;
          updatedItems[index].total -= finalDicount;
        } else {
          let total = updatedItems[index].unit * updatedItems[index].quantity;

          updatedItems[index].discount_per = e.target.value;
          let discountPrice = (updatedItems[index].unit * e.target.value) / 100;
          updatedItems[index].discount_price = discountPrice;
          updatedItems[index].total = total;
          let finalDicount = discountPrice * updatedItems[index].quantity;
          updatedItems[index].total -= finalDicount;
        }
        setTableData(updatedItems);
      }

      // setTableData(updatedItems);

      // setSub_Total(updatedItems[index].sub_Total);
      updatePrice(index);
    }

    // }
    else {
      alert("Please select positive value");
      return 0;
    }
    // } else {
    //   updatedItems[index].discount_per = "";
    //   updatedItems[index].discount_price = "";
    //   // updatedItems.total =  updatedItems.total;
    //   setTableData(updatedItems);
    // }
  };

  const changeAmountTypeOld = (e, id) => {
    e.preventDefault();
    let value = e.target.value || "0";

    let dis = 0;
    const index = tableData.findIndex((item) => item.id === id);
    let updatedItems = [...tableData];
    // if (e.target.value) {
    if (value > -1) {
      if (amountType == "no") {
        return 0;
      } else if (amountType == "price") {
        updatedItems[index].discount_price = e.target.value;
        let discount_mrp = e.target.value * updatedItems[index].quantity;
        updatedItems[index].total = updatedItems[index].total - e.target.value;
        // set discount price
        updatedItems[index].discount_per =
          (e.target.value * 100) / updatedItems[index].mrp;
        setTableData(updatedItems);
      } else {
        let roundTotal = parseFloat(
          parseFloat(updatedItems[index].mrp) * updatedItems[index].quantity
        );
        updatedItems[index].discount_per = value;
        let totla_per = parseFloat((roundTotal * value) / 100);
        updatedItems[index].discount = totla_per;

        dis =
          (updatedItems[index].discount_per * updatedItems[index].mrp) / 100;

        updatedItems[index].discount_price = dis;
        setTableData(updatedItems);
      }

      // setTableData(updatedItems);

      // setSub_Total(updatedItems[index].sub_Total);
      // updatePrice(index);
    }

    // }
    else {
      alert("Please select positive value");
      return 0;
    }
    // } else {
    //   updatedItems[index].discount_per = "";
    //   updatedItems[index].discount_price = "";
    //   // updatedItems.total =  updatedItems.total;
    //   setTableData(updatedItems);
    // }
  };

  const changeGstOld = (id) => {
    setGst(id);
    let updatedItems = [...tableData];
    const lastItemIndex = updatedItems.length - 1;
    const lastItem = updatedItems[lastItemIndex];
    const total_gst =
      parseFloat(
        updatedItems[lastItemIndex].mrp * updatedItems[lastItemIndex].check_gst
      ) / 100;
    if (gst == 1) {
      updatedItems[lastItemIndex].total -= total_gst;
      updatedItems[lastItemIndex].gst = 0;
      // Now, set the updated items back to state
      setTableData(updatedItems);
    } else {
      updatedItems[lastItemIndex].total += total_gst;

      updatedItems[lastItemIndex].gst = updatedItems[lastItemIndex].check_gst;
      // Now, set the updated items back to state
      setTableData(updatedItems);
    }
  };
  const changeGst = (id) => {
    setGst(id);
    let updatedItems = [...tableData];
    updatedItems = updatedItems.map((item, index) => {
      const total_gst = parseFloat(item.total * item.check_gst) / 100;
      if (gst == 1) {
        let product_total = parseFloat(item.unit * item.quantity);
        product_total -= item.discount_price ? item.discount_price : 0;
        item.total = product_total;
        let discountPrice = (item.unit * item.discount_per) / 100;
        item.discount_price = discountPrice * item.quantity;
        item.gst = 0;
      } else {
        let product_total = parseFloat(item.mrp * item.quantity);
        product_total -= item.discount_price ? item.discount_price : 0;
        item.total = product_total;
        item.gst = item.check_gst;
        let discountPrice = (item.mrp * item.discount_per) / 100;
        item.discount_price = discountPrice * item.quantity;
      }
      return item;
    });
    setTableData(updatedItems);
  };

  const setArea = (e, id) => {
    const updatedItems = [...tableData];
    const index = tableData.findIndex((item) => item.id === id);
    updatedItems[index].area = e;
    setTableData(updatedItems);
  };

  const changeSelectionNew = (e) => {
    let Row = 0;
    const newSelectionId = e.target.value;

    const updatedItems = tableData.map((item) => {
      // Step 2: Deactivate all items that don't match the selected selection ID
      const isSelected = item.selection == newSelectionId;

      if (isSelected) {
        Row += 1;
      }
      // Step 3: Update the `active` property based on the selection ID
      const updatedItem = {
        ...item, // Copy all properties of the current row
        active: isSelected, // Set active to true if it matches
      };

      return updatedItem;
    });

    // Step 4: Calculate additional state values (e.g., totalSlots, secName, etc.)
    const selectedItem = updatedItems.find(
      (item) => item.selection == newSelectionId
    );
    const newSecName = selectedItem ? selectedItem.selection_name || "" : "";
    const newSubTotal = selectedItem ? selectedItem.sub_Total || "" : "";

    let totalSlots = tableData.filter((item) => item.brand === "").length;

    // Step 5: Use the functional state update to update the table data and other state values
    setTableData(updatedItems); // This triggers a re-render with updated data
    setSecName(newSecName); // Update the selected section name
    setSub_Total(newSubTotal); // Update the selected subtotal
    setSelectionID(newSelectionId); // Update the selected ID
    if (!selectedItem) {
      addRow(newSelectionId);
    }
  };

  const changeSelection = (e) => {
    let Row = 0;
    const newSelectionId = e.target.value;

    let updatedItems = [...tableData];

    let current_sel = "";
    var total_slots = "";
    setSecName("");
    setSelectionID(newSelectionId);
    for (let i = 0; i < updatedItems.length; i++) {
      // updatedItems[i].selection = newSelectionId
      if (updatedItems[i].brand == "") {
        total_slots += 1;
      }
      if (updatedItems[i].selection != newSelectionId) {
        updatedItems[i].active = false;
        setSecName("");
        setSub_Total("");
      } else if (updatedItems[i].selection == newSelectionId) {
        setSecName("");

        updatedItems[i].active = true;
        current_sel = updatedItems[i].selection_name
          ? updatedItems[i].selection_name
          : "";
        setSub_Total(updatedItems[i].sub_Total);
      }
      if (updatedItems[i].selection == newSelectionId) {
        // updatedItems[i].selection = newSelectionId
        Row += 1;
      }
    }

    setTableData(updatedItems);

    setSecName(current_sel);
    if (Row <= 0) {
      if (is_Empty(newSelectionId)) {
        addRow(newSelectionId);
      }
    }
  };

  const is_Empty = (newSelectionId) => {
    let updatedItems = [...tableData];
    let status = true;
    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].selection == newSelectionId) {
        status = false;
      }
      if (status == false) {
        break;
      }
    }
    return status;
  };
  const changesectionName = (e) => {
    const value = e.target.value;
    setSecName(value);

    let updatedItems = [...tableData];
    for (let i = 0; i < updatedItems.length; i++) {
      if (parseInt(updatedItems[i].selection) === parseInt(selectionId)) {
        updatedItems[i].selection_name = value;
      }
    }

    setTableData(updatedItems);
  };
  const resetClient = (e) => {
    e.preventDefault();
    // viewclent(e);
    setData();
    setclientId(0);
    if (dropdownRef.current) {
      dropdownRef.current.selectedIndex = 0;
    }
  };

  const editProject = async () => {
    try {
      const editTable = await UserSection.editProject(projectId);
    } catch (error) {
      setLoading(false);
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  // upload excel
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFiles(file);

    const reader = new FileReader();
    let allSheetData = [];

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName, index) => {
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        if (index == 0) {
          allSheetData[sheetName] = jsonData.slice(0);
        } else {
          allSheetData[sheetName] = jsonData.slice(0);
        }
      });

      const sectionsArray = Object.entries(allSheetData);
      // const dataString  = JSON.stringify(allSheetData);
      importcsvfile1(sectionsArray);

      setExcelData(sectionsArray);
      setError("");
    };

    reader.readAsArrayBuffer(file);
  };

  // upload data
  const importcsvfile = () => {
    setupload(true);
  };
  const importcsvfile1 = async (data) => {
    try {
      let stop = false;
      for (const selection of data) {
        const selectionName = selection[0];
        const selectionData = selection[1];
        const rowCount = selectionData.length;

        if (rowCount > 300) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${selectionName} has exceeded the row limit of 300. It has ${rowCount} rows.`, // Correct template string usage
          }).then((result) => {
            // You can handle the user's action here if needed, for example:
            if (result.isConfirmed) {
              window.location.href = "/user/projects";
            }
          });

          stop = true;
          break;
        } else {
          console.log(`${selectionName} has ${rowCount} rows.`);
        }
      }
      if (stop) {
        return;
      }
      const updatedItems = [...tableData];
      setLoading(true);

      if (excelData) {
        const large_id = getLartgestId();
        const importData = await UserSection.excelImport(data); // Make API call
        setLoading(false);

        let ref_total = 0;
        const has_gst = 0;

        // Map through the imported data and prepare the new data for the table
        const newData = importData.data.map((item, index) => ({
          has_gst: gst == 1 ? parseFloat(item.gst) : 0,
          ref_total:
            gst == 1 ? (parseFloat(item.gst) * parseFloat(item.mrp)) / 100 : 0,
          id: large_id + (index + 1),
          check: false,
          sku_code: item.sku_code,
          brand: item.brand,
          product_desc: item.product_desc,
          finish: item.finish,
          image: item.image,
          quantity: item.qty,
          collection: item.collection,
          area: item.area,
          mrp: item.mrp,
          discount_price:
            gst == 1 ? item.discount_price : item.discount_price_non_gst,
          discount_per:
            gst == 1 ? item.discount_per : item.discount_per_non_gst,
          gst: gst == 1 ? item.gst : 0,
          total:
            gst == 1 ? parseFloat(item.gst_total) : parseFloat(item.non_gst),
          discount: "",
          selection: item.section_id ? item.section_id : selectionId,
          active: item.section_id == selectionId ? true : false,
          selection_name: secName,
          check_gst: item.gst,
          Spare: item.spare_fg,
          Group: item.product_typ_group,
          unit: item.unit,
        }));

        // Combine the new data with existing table data
        const combinedData = [...tableData, ...newData];
        if (
          updatedItems.length > 0 &&
          updatedItems[0] &&
          updatedItems[0].sku_code === ""
        ) {
          setTableData(newData);
          setRowsId(tableData.length);
        } else {
          const index = updatedItems.length;
          updatedItems[index] = newData;
          setRowsId(26);
        }

        if (gst == 1) {
          setexcludeGst(true);
        } else {
          setincludeGst(true);
        }
      } else {
        setError("Please select a file."); // Set error message if no file selected
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle errors here
    }
  };

  const getLartgestId = () => {
    // Ensure tableData is not empty
    if (tableData.length === 0) {
      return 1; // Return 1 or whatever default id you want if no items are found
    }

    // Find the largest id in tableData
    const largestId = tableData.reduce((maxId, item) => {
      return Math.max(maxId, item.id);
    }, 0); // Start with 0 as the initial value, assuming IDs are non-negative

    return largestId + 1;
  };

  const downloadcsv = async (e) => {
    try {
      e.preventDefault();
      const url =
        "https://quatationsapis.groheapp.in/public/excel/sku_data.xlsx";
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "sku_data.xlsx";
      anchor.click();
    } catch (error) {
      setLoading(true);
      console.log(error);
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
    }
  };

  const place = selectionId ? selectionId : 1;
  const concatenatedString = `Enter selection name ${selectionId}`;

  let total_amount = 0;
  let total_quantity = 0;
  let total_discount_price = 0;

  const [areAllChecked, setAreAllChecked] = useState(false);

  const selectAll = () => {
    const updatedTableData = tableData.map((item) => {
      if (item.selection == selectionId) {
        return { ...item, check: !areAllChecked };
      }
      return item;
    });

    setTableData(updatedTableData);
    setAreAllChecked(!areAllChecked);
  };

  const removeErrorMessage = (skuCode) => {
    setErrorMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.sku_code !== skuCode)
    );
  };

  // add button disable
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const filteredData = tableData.filter(
      (item) => item.selection == selectionId
    );
    const allChecked = filteredData.every((item) => item.check == true);

    if (filteredData.length == 0) {
      setDisable(false);
    } else {
      setDisable(allChecked);
    }
  }, [tableData, selectionId]);

  const trash =
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.5001 6H3.5" stroke="#d00606" stroke-width="1.5" stroke-linecap="round"></path> <path d="M18.8332 8.5L18.3732 15.3991C18.1962 18.054 18.1077 19.3815 17.2427 20.1907C16.3777 21 15.0473 21 12.3865 21H11.6132C8.95235 21 7.62195 21 6.75694 20.1907C5.89194 19.3815 5.80344 18.054 5.62644 15.3991L5.1665 8.5" stroke="#d00606" stroke-width="1.5" stroke-linecap="round"></path> <path d="M9.5 11L10 16" stroke="#d00606" stroke-width="1.5" stroke-linecap="round"></path> <path d="M14.5 11L14 16" stroke="#d00606" stroke-width="1.5" stroke-linecap="round"></path> <path d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6" stroke="#d00606" stroke-width="1.5"></path> </g></svg>';

  const [dropDownData, setDropDownData] = useState([]);
  const [selectedFinish, setSelectedFinish] = useState(null);

  const [selectedSpare, setSelectedSpare] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const token = localStorage.getItem("user");
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await UserSection.allProduct();
        setDropDownData(data.data);
      } catch (error) {
        console.log(error.message);
      } finally {
      }
    };
    fetchProjects();
  }, [token]);
  const getFinishOptions = () => {
    if (!dropDownData || !Array.isArray(dropDownData)) {
      console.warn("Try again later.");
      return [];
    }

    const finishOptions = dropDownData.map((product) => ({
      value: product.finish,
      label: product.finish,
    }));

    const uniqueFinishOptions = [
      ...new Map(finishOptions.map((item) => [item.value, item])).values(),
    ];

    return uniqueFinishOptions.sort((a, b) => a.value.localeCompare(b.value));
  };

  // not spare actualy its data for type group
  const getSpareFgOptions = () => {
    const groupData = dropDownData.filter(
      (item) => item.collection === selectedCollection
    );
    const spareFgOptions = groupData?.map((product) => ({
      value: product.product_typ_group,
      label: product.product_typ_group,
    }));
    const uniqueSpareFgOptions = [
      ...new Map(spareFgOptions?.map((item) => [item.value, item])).values(),
    ];
    return uniqueSpareFgOptions
      .sort((a, b) => a.value.localeCompare(b.value))
      .filter((item) => item.value != "");
  };

  const getTypeWithFinish = () => {
    const groupData = dropDownData.filter(
      (item) => item.finish === selectedFinish
    );
    const spareFgOptions = groupData?.map((product) => ({
      value: product.product_typ_group,
      label: product.product_typ_group,
    }));
    const uniqueSpareFgOptions = [
      ...new Map(spareFgOptions?.map((item) => [item.value, item])).values(),
    ];
    return uniqueSpareFgOptions
      .sort((a, b) => a.value.localeCompare(b.value))
      .filter((item) => item.value != "");
  };

  const getCollectionOptions = () => {
    const collectionOptions = dropDownData.map((product) => ({
      value: product.collection,
      label: product.collection,
    }));
    const uniqueCollectionOptions = [
      ...new Map(collectionOptions?.map((item) => [item.value, item])).values(),
    ];
    return uniqueCollectionOptions.sort((a, b) =>
      a.value.localeCompare(b.value)
    );
  };

  const handleFilter = async () => {
    if (!selectedSpare) {
      toast.warn("Select Product Type Group", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }
    if (!searchqty) {
      toast.warn("Quantity Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }

    setLoading(true);
    try {
      let filteredData = dropDownData.filter((item) => {
        const isSpareMatch =
          !selectedSpare || selectedSpare.includes(item.product_typ_group); // Check if the item is in selectedSpare array
        const isFinishMatch = !selectedFinish || item.finish === selectedFinish;
        const isCollectionMatch =
          !selectedCollection || item.collection === selectedCollection;

        return isSpareMatch && isFinishMatch && isCollectionMatch;
      });

      // } else {
      //   filteredData = dropDownData.filter(
      //     (item) =>
      //       selectedCollection === "" || item.collection === selectedCollection
      //   );
      // }
      let selection = selectionId;

      if (tableData.length + filteredData.length > 4500) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Items Are Exceeding The Selections ",
        });
        // setLoading(false);
        return;
      }

      let currentSelectionLength = tableData.filter(
        (item) => item.selection == selection
      ).length;

      if (currentSelectionLength + filteredData.length > 300) {
        const exceedingItems =
          currentSelectionLength + filteredData.length - 300;
        Swal.fire({
          title: "Can't Proceed !",
          text: `Selection limit of 300 exceeded by ${exceedingItems} items. You can try adding them to another selection.`,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
          // setLoading(false);
        });
        return;
      } else {
        const large_id = getLartgestId();
        const newData = filteredData.map((item, index) => {
          let hasgst;
          let unit_price =
            parseFloat(item.mrp * 100) / parseFloat(100 + item.gst);
          let unit_mrp = unit_price;
          let ref_total;
          let dis_price;
          if (gst == "1") {
            hasgst = item.gst;
            dis_price =
              (parseFloat(item.mrp) * (percFilter ? percFilter : 0)) / 100;

            ref_total = item.mrp * searchqty;
            ref_total -= dis_price * searchqty;
          } else {
            hasgst = 0;
            dis_price =
              (parseFloat(unit_mrp) * (percFilter ? percFilter : 0)) / 100;

            ref_total = unit_mrp * searchqty;
            ref_total -= dis_price * searchqty;
          }
          return {
            id: large_id + (index + 1),
            check: false,
            sku_code: item.sku_code,
            brand: item.brand,
            product_desc: item.product_desc,
            finish: item.finish,
            collection: item.collection,
            image: item.image,
            quantity: Number(searchqty),
            area: "null",
            mrp: item.mrp,
            discount_price: dis_price,
            discount_per: percFilter ? percFilter : "0",
            gst: gst == 1 ? item.gst : 0,
            total: ref_total,
            discount: "",
            selection: selection,
            active: true,
            selection_name: secName,
            client_id: null,
            type: "add",
            check_gst: item.gst,
            Spare: item.spare_fg,
            Group: item.product_typ_group,
            unit: unit_mrp,
          };
        });

        const updatedTableData = tableData.filter(
          (item) => item.sku_code !== "" && item.sku_code !== null
        );
        setTableData([...updatedTableData, ...newData]);
        setExpandMore(false);
        setsearchqty("");
        setPercFilter("");
        setActiveTab(0);
        setSelectedCollection(null);
        setSelectedSpare(null);
        setSelectedFinish(null);
        toast.success(`${newData.length} Items Added Successfully!`);
        // setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const formatNumber = (value) => {
    let val = value ? value : 0;
    if (typeof val === "string") {
      val = val.replace(/,/g, "");
    }

    val = parseFloat(val);

    //  nearest whole number
    val = Math.round(val);

    // commas
    return val;
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedSpare("");
    setSelectedFinish("");
    setSelectedCollection("");
  };

  //custom option for product type select with checkbox
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    const isSelected = selectedSpare ? selectedSpare.includes(data.value) : "";

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem",
          cursor: "pointer",
        }}
      >
        <label>{data.label}</label>
        <input
          type="checkbox"
          checked={isSelected}
          readOnly
          style={{ marginLeft: "1rem" }}
        />
      </div>
    );
  };

  // Handle Change   for spare which is working for product type group
  const handleChangeSpare = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedSpare(selectedValues);
  };

  console.table(tableData);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        theme="dark"
      />
      {errorMessages.length > 0 && (
        <NotificationDrawer
          initialErrorMessages={errorMessages}
          selectionId={selectionId}
          onRemoveMessage={removeErrorMessage}
        />
      )}
      <UserTopbar heading="Create quotation" sidebar={isSidebarOpen} />
      {loading && <Loader />}
      {(progress && totalStep) > 0 && (
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            width: 100,
            bgcolor: "background.paper",
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            textAlign: "center",
          }}
        >
          <CircularProgress
            variant="determinate"
            value={(currentStep / totalStep) * 100}
            size={60}
          />
          <Typography variant="caption" sx={{ mt: 1 }}>
            {`${Math.round((currentStep / totalStep) * 100)}%`}
          </Typography>
        </Box>
      )}

      <div
        className={`z-10 sticky top-0   mb-2 border border-black bg-white/70 rounded-xl `}
      >
        <div className="md:flex justify-between items-center gap-x-3 w-full p-2 pb-0 ">
          <Autocomplete onSelected={handleSelected} />

          <div onClick={() => setExpandMore(!expandMore)}>
            <SmartSearchButton />
          </div>

          <div className="font-mono flex justify-center gap-5">
            <Tooltip title="Add Row At Bottom" arrow>
              <button
                className={`relative overflow-hidden px-6 py-2 bg-black border border-white text-white font-semibold text-sm transition-all duration-300 focus:outline-none group w-36 hover:bg-white hover:border-black hover:text-black`}
                onClick={() => addRow(null)}
                disabled={disable}
              >
                <span className="absolute inset-0 bg-white transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                <span className="relative flex items-center justify-center gap-2">
                  <AddCircleOutline className="scale-110" />
                  Add Row
                </span>
              </button>
            </Tooltip>

            <button
              className="relative overflow-hidden px-6 py-2 bg-black border border-white text-white font-semibold text-sm transition-all duration-300 focus:outline-none group w-36 hover:bg-white hover:border-black hover:text-black"
              onClick={(e) => removeRow(e)}
            >
              <span className="absolute inset-0 bg-white transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
              <span className="relative flex items-center justify-center gap-2">
                <Delete className="scale-110" />
                Delete Row
              </span>
            </button>

            <button
              className="relative overflow-hidden px-6 py-2 bg-black border border-white text-white font-semibold text-sm transition-all duration-300 focus:outline-none group w-36 hover:bg-white hover:border-black hover:text-black"
              onClick={(e) => resetState(e)}
            >
              <span className="absolute inset-0 bg-white transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
              <span className="relative flex items-center justify-center gap-2">
                <RotateLeftRoundedIcon className="scale-110" />
                Reset
              </span>
            </button>

            <button
              onClick={saveProjectData}
              className="relative overflow-hidden px-7 py-2 bg-black border border-white text-white font-semibold text-sm transition-all duration-300 focus:outline-none group w-36 hover:bg-white hover:border-black hover:text-black"
            >
              <span className="absolute inset-0 bg-white transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
              <span className="relative flex items-center justify-center gap-2">
                <PostAddOutlinedIcon className="scale-110" />
                {projectId ? "Update Quote" : "Save Quote"}
              </span>
            </button>
          </div>

          <div className="flex border border-blue-950 pl-2 -pr-2 rounded-3xl bg-gray-100 items-center">
            <Tooltip title={"Download Format"} arrow>
              <IconButton
                color="primary"
                className="p-3  h-[3rem] w-[3rem]"
                onClick={(e) => downloadcsv(e)}
              >
                <DownloadForOfflineIcon className=" cursor-pointer scale-150 hover:scale-125" />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Upload File"} arrow>
              <label
                htmlFor="fileInput"
                className="inline-block px-4 py-2   rounded "
              >
                <DriveFolderUploadSharpIcon className="text-orange-600 cursor-pointer scale-150 hover:scale-125" />
                <input
                  id="fileInput"
                  className="hidden"
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                />
              </label>
            </Tooltip>
          </div>
        </div>

        {expandMore && (
          <Modal
            open={expandMore}
            onClose={() => {
              // setExpandMore(false);
              // setsearchqty("");
              // setPercFilter("");
              // setSelectedCollection("");
              // setSelectedFinish("");
              // setSelectedSpare("");
            }}
            aria-labelledby="filter-modal-title"
            aria-describedby="filter-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              {/* <Typography
                id="filter-modal-title"
                variant="h6"
                component="h2"
                mb={2}
                className="text-center font-semibold"
              >
                Filters
              </Typography> */}

              {/* Tabs */}
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Collection & Group" />
                <Tab label="Finish & Group" />
              </Tabs>

              {/* Tab Content */}
              {activeTab === 0 && (
                <div className="space-y-3 mt-4">
                  {/* Collection Selection */}
                  <Select
                    value={
                      selectedCollection
                        ? options.find(
                            (option) => option.value === selectedCollection
                          )
                        : null
                    }
                    onChange={(option) => setSelectedCollection(option.value)}
                    options={getCollectionOptions()}
                    placeholder="Select Collection"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.3rem",
                        borderRadius: "0.28rem",
                      }),
                    }}
                  />
                  {/* Spare Selection with Collection */}
                  <Select
                    value={getSpareFgOptions().filter((option) =>
                      selectedSpare ? selectedSpare.includes(option.value) : ""
                    )}
                    onChange={handleChangeSpare}
                    options={getSpareFgOptions()}
                    placeholder="Select Product Type Group"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option: CustomOption }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.3rem",
                        borderRadius: "0.28rem",
                      }),
                    }}
                  />

                  {/* multiple select */}
                </div>
              )}

              {activeTab === 1 && (
                <div className="space-y-3 mt-4">
                  {/* Finish Selection */}
                  <Select
                    value={
                      selectedFinish
                        ? options.find(
                            (option) => option.value === selectedFinish
                          )
                        : null
                    }
                    onChange={(option) => setSelectedFinish(option.value)}
                    options={getFinishOptions()}
                    placeholder="Select Finish"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.3rem",
                        borderRadius: "0.28rem",
                      }),
                    }}
                  />

                  <Select
                    value={getSpareFgOptions().filter((option) =>
                      selectedSpare.includes(option.value)
                    )}
                    onChange={handleChangeSpare}
                    options={getTypeWithFinish()}
                    placeholder="Select Product Type Group"
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option: CustomOption }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "0.3rem",
                        borderRadius: "0.28rem",
                      }),
                    }}
                  />
                </div>
              )}

              {/* Quantity and Discount Inputs */}
              <div className="space-y-3 mt-4">
                <TextField
                  type="text"
                  name="searchqty"
                  fullWidth
                  value={searchqty}
                  onChange={(e) => setsearchqty(e.target.value)}
                  placeholder="Enter Quantity"
                  variant="outlined"
                />

                <TextField
                  type="text"
                  name="percFilter"
                  fullWidth
                  value={percFilter}
                  onChange={(e) => setPercFilter(e.target.value)}
                  placeholder="Enter Discount (%)"
                  variant="outlined"
                />
                <div className=" flex gap-4">
                  {/* Apply Filters Button */}

                  <button
                    onClick={() => {
                      handleFilter();
                      handleDebounce();
                    }}
                    disabled={isDisabled}
                    className="relative overflow-hidden px-6 py-2 bg-white border border-blue-500 text-blue-500 font-semibold text-md transition-all duration-300 focus:outline-none group w-full rounded"
                  >
                    <span className="absolute inset-0 bg-blue-500 transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                    <span className="relative  transition-colors duration-300 group-hover:text-white flex items-center justify-center gap-2">
                      {isDisabled ? (
                        <SyncIcon className="scale-125" />
                      ) : (
                        <ManageSearchIcon className="scale-125" />
                      )}
                      Apply
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      setPercFilter("");
                      setsearchqty();
                      setSelectedCollection("");
                      setSelectedFinish("");
                      setSelectedSpare("");
                      setActiveTab(0);
                    }}
                    disabled={isDisabled}
                    className="relative overflow-hidden px-6 py-2 bg-white border border-green-500 text-green-500 font-semibold text-md transition-all duration-300 focus:outline-none group w-full rounded"
                  >
                    <span className="absolute inset-0 bg-green-500 transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                    <span className="relative  transition-colors duration-300 group-hover:text-white flex items-center justify-center gap-2">
                      <SearchOffIcon className="scale-125" />
                      Clear
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      setExpandMore(false);
                      setsearchqty("");
                      setPercFilter("");
                      setActiveTab(0);
                      setSelectedCollection("");
                      setSelectedFinish("");
                      setSelectedSpare("");
                    }}
                    disabled={isDisabled}
                    className="relative overflow-hidden px-6 py-2 bg-white border border-red-500 text-red-500 font-semibold text-md transition-all duration-300 focus:outline-none group w-full rounded"
                  >
                    <span className="absolute inset-0 bg-red-500 transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                    <span className="relative  transition-colors duration-300 group-hover:text-white flex items-center justify-center gap-2">
                      <CloseFullscreenIcon className="scale-125" />
                      Close
                    </span>
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        <section className="md:flex justify-between items-center gap-x-3 w-full p-2 pt-0 pb-0 -mt-4 ">
          <select
            className="flex-1 max-w-[10rem] py-3 form-select rounded text-center mt-3"
            onChange={(e) => changeSelection(e)}
            name="selection"
            id="selection"
            required
            value={selectionId}
          >
            {selection &&
              selection.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                  className={item.id === selectionId ? "selected" : ""}
                >
                  {item.name}
                </option>
              ))}
          </select>

          <input
            type="text"
            name={`sectionName${selectionId}`}
            id={`sectionName${selectionId}`}
            className="flex-1 py-3 mt-3 form-text rounded text-black-500 text-center"
            onChange={(e) => changesectionName(e)}
            value={secName}
            placeholder="Enter Selection Name*"
          />
          {/* <input type="text" name="selectionClient" id="selectionClient" className="flex-1 form-text rounded text-black-500 text-center" placeholder="Enter Client ID" onChange={(e) => setselectionClient(e.target.value)} value={selectionClient} /> */}
          {/* gst */}
          <div className="flex-1 mt-3">
            <Select
              className="py-3"
              value={
                selectionClient
                  ? options.find((option) => option.value == selectionClient)
                  : null
              }
              onChange={handleSelectChange}
              options={options}
              placeholder="Select Client"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "0.3rem",
                  borderRadius: "0.28rem",
                  backgroundColor: "white",
                  borderColor: "#4A5568",
                }),
              }}
            />
          </div>

          <fieldset className="border border-gray-900 bg-white rounded-md p-1 ">
            <legend className="text-gray-700 font-medium">Valid Upto</legend>
            <input
              type="date"
              value={selectedDate || ""}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="py-0 w-[10rem] text-primary border border-white rounded-md focus:ring-white"
            />
          </fieldset>

          <fieldset className="border border-gray-900 bg-white rounded-md p-1">
            <legend className="text-gray-700 font-medium">GST * </legend>
            <div className="flex gap-x-3">
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox   text-primary border-2 border-gray-400 rounded-md focus:ring-primary"
                  name="get"
                  value="1"
                  onChange={(e) => changeGst(1)}
                  checked={gst == 1 ? true : false}
                  // disabled={
                  //   gst  == 1
                  //     ? true
                  //     : false || tableData.some((row) => row.sku_code !== "")
                  // }
                  disabled={
                    tableData.some((row) => row.sku_code !== "") ? true : false
                  }
                ></input>
                <label htmlFor="discountPercent">Inclusive </label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-400 rounded-md focus:ring-primary"
                  name="get"
                  value="0"
                  onChange={(e) => changeGst(0)}
                  checked={gst == 0 ? true : false}
                  // disabled={
                  //   gst  == 1
                  //     ? true
                  //     : false || tableData.some((row) => row.sku_code !== "")
                  // }
                  disabled={
                    tableData.some((row) => row.sku_code !== "") ? true : false
                  }
                ></input>
                <label htmlFor="discountPrice"> Exclusive </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="border border-gray-900 bg-white rounded-md p-1">
            <legend className="text-gray-700 font-medium">Discount * </legend>
            <div className="flex gap-x-3">
              {/*<div className="flex items-center gap-x-1">
                 <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-400 rounded-md focus:ring-primary"
                  name="discount"
                  value="no"
                  onChange={(e) => setAmountType("no")}
                  checked={amountType === "no" ? true : false}
                  disabled={
                    projectId &&
                    (amountType === "percentage" || amountType === "price")
                  }
                ></input> 
               <label htmlFor="discountPercent">No </label> 
              </div>*/}
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-400 rounded-md focus:ring-primary"
                  name="discount"
                  value="price"
                  onChange={(e) => setAmountType("price")}
                  checked={amountType === "price" ? true : false}
                  // disabled={
                  //   projectId &&
                  //   (amountType === "percentage" || amountType === "no")
                  // }
                ></input>
                <label htmlFor="discountPrice"> Price</label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-400 rounded-md focus:ring-primary"
                  name="discount"
                  value="per"
                  onChange={(e) => setAmountType("percentage")}
                  checked={amountType === "percentage" ? true : false}
                  // disabled={
                  //   projectId && (amountType === "no" || amountType === "price")
                  // }
                ></input>
                <label htmlFor="discountPercent"> %</label>
              </div>
            </div>
          </fieldset>
        </section>
      </div>

      <main className="flex-1 overflow-x-auto w-full h-[80vh] border-b  border-gray-700">
        <table className="text-sm font-mono productTable w-full text-center">
          <thead className="sticky top-0">
            <tr className=" bg-gray-900 text-white">
              <th className="border px-1 border-slate-600 font-semibold ...">
                SL No
              </th>
              <th className="border border-slate-600 font-semibold p-5 ...">
                <BallotIcon
                  className="scale-125 hover:scale-150 hover:text-orange-500"
                  onClick={selectAll}
                />
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Product Code{" "}
              </th>
              <th className="  min-w-[6rem] border border-slate-600 font-semibold ...">
                Area
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Brand
              </th>
              <th className="border max-w-[6rem] border-slate-600 font-semibold ...">
                Product Description
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Finish
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Collection
              </th>

              <th className="border border-slate-600 font-semibold ...">
                Product Type
              </th>
              <th className="border min-w-[6rem] border-slate-600 font-semibold ...">
                Product Image
              </th>
              <th className="border border-slate-600 font-semibold gap-4 ...">
                Quantity*{" "}
                <span className="flex gap-1 items-center justify-center">
                  <ArrowDropUpIcon
                    onClick={() => sortTableDataAsc("quantity")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                  <ArrowDropDownIcon
                    onClick={() => sortTableDataDesc("quantity")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                </span>
              </th>

              <th className="border border-slate-600 font-semibold ...">
                MRP (₹){" "}
                <span className="flex gap-1 items-center justify-center">
                  <ArrowDropUpIcon
                    onClick={() => sortTableDataAsc("mrp")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                  <ArrowDropDownIcon
                    onClick={() => sortTableDataDesc("mrp")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                </span>
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Unit Price
              </th>
              <th className="w-[5rem] border border-slate-600 font-semibold ...">
                Discount Price (₹)
                <span className="flex gap-1 items-center justify-center">
                  <ArrowDropUpIcon
                    onClick={() => sortTableDataAsc("discount_price")}
                    className="bg-white ml-1 text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                  <ArrowDropDownIcon
                    onClick={() => sortTableDataDesc("discount_price")}
                    className="bg-white ml-1 text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                </span>
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Discount %
                <span className="flex gap-1 items-center justify-center">
                  <ArrowDropUpIcon
                    onClick={() => sortTableDataAsc("discount_per")}
                    className="bg-white ml-1 text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                  <ArrowDropDownIcon
                    onClick={() => sortTableDataDesc("discount_per")}
                    className="bg-white ml-1 text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                </span>
              </th>
              <th className="border border-slate-600 font-semibold ...">
                GST %
              </th>
              <th className="border border-slate-600 font-semibold ...">
                Total (₹)
                <span className="flex gap-1 items-center justify-center">
                  <ArrowDropUpIcon
                    onClick={() => sortTableDataAsc("total")}
                    className="bg-white ml-1 text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                  <ArrowDropDownIcon
                    onClick={() => sortTableDataDesc("total")}
                    className="bg-white ml-1 text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110"
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white/80">
            {tableData
              .filter((row) => row.active && row.selection == selectionId)
              .map(function (row, index) {
                if (row.active) {
                  total_amount += parseFloat(row.total);
                  total_quantity += parseFloat(row.quantity);
                  total_discount_price += parseFloat(
                    row.discount_price * row.quantity
                  );
                  return (
                    <tr
                      key={row.id + row.sku_code + index}
                      id={`sku-${row.id}`}
                    >
                      <td>{index + 1}</td>
                      <td className=" border-b border-gray-300 p-2">
                        <input
                          type="checkbox"
                          checked={row.check === true}
                          class="orm-checkbox h-5 w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary cursor-pointer"
                          onChange={(e) =>
                            handleCheckboxChange(e.target.value, row.id)
                          }
                        ></input>{" "}
                        <br />
                        <button
                          onClick={(e) => removeRowOne(e, row.id)}
                          className=" text-red-600 hover:text-red-700 mt-5 hover:scale-125 border rounded-full border-red-600 p-1 hover:bg-gray-200"
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: trash }}
                            style={{ width: "24px", height: "24px" }}
                          />
                        </button>
                      </td>

                      <td>
                        <Tooltip title={row.sku_code} arrow>
                          <input
                            class=" text-xs font-thin text-gray-700 border w-20"
                            id="sku_code"
                            type="text"
                            onKeyUp={(e) => handleKeyUp(e, row.id)}
                            defaultValue={row.sku_code ? row.sku_code : ""}
                            size="6"
                            style={{ borderRadius: "10px" }}
                          />
                        </Tooltip>
                      </td>
                      {/* <td className="text-justify ">
                        <label>
                          <input
                            type="radio"
                            name={"area" + row.id}
                            onChange={() => setArea("Shower", row.id)}
                            value="Shower"
                            checked={row.area === "Shower"}
                            className="text-center"
                          />{" "}
                          Shower
                        </label>
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={() => setArea("Basin", row.id)}
                          value="Basin"
                          checked={row.area === "Basin"}
                        ></input>{" "}
                        Basin
                        <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={(e) => setArea("EWC", row.id)}
                          value="EWC"
                          checked={row.area === "EWC"}
                        ></input>{" "}
                        EWC <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={() => setArea("Kitchen", row.id)}
                          value="Kitchen"
                          checked={row.area === "Kitchen"}
                        ></input>{" "}
                        Kitchen
                        <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={(e) => setArea("Faucets", row.id)}
                          value="Faucets"
                          checked={row.area === "Faucets"}
                        ></input>{" "}
                        Faucets
                      </td> */}
                      <td className="text-justify">
                        <label className="text-center cursor-pointer">
                          <input
                            type="radio"
                            name={"area" + [row.id]}
                            onChange={() => setArea("Shower", row.id)}
                            value="Shower"
                            checked={row.area === "Shower"}
                          />{" "}
                          Shower
                        </label>
                        <br />
                        <label className="text-center cursor-pointer">
                          <input
                            type="radio"
                            name={"area" + row.id}
                            onChange={() => setArea("Basin", row.id)}
                            value="Basin"
                            checked={row.area === "Basin"}
                          />{" "}
                          Basin
                        </label>
                        <br />
                        <label className="text-center cursor-pointer">
                          <input
                            type="radio"
                            name={"area" + row.id}
                            onChange={() => setArea("EWC", row.id)}
                            value="EWC"
                            checked={row.area === "EWC"}
                          />{" "}
                          EWC
                        </label>
                        <br />
                        <label className="text-center cursor-pointer">
                          <input
                            type="radio"
                            name={"area" + row.id}
                            onChange={() => setArea("Kitchen", row.id)}
                            value="Kitchen"
                            checked={row.area === "Kitchen"}
                          />{" "}
                          Kitchen
                        </label>
                        <br />
                        <label className="text-center cursor-pointer">
                          <input
                            type="radio"
                            name={"area" + row.id}
                            onChange={() => setArea("Faucets", row.id)}
                            value="Faucets"
                            checked={row.area === "Faucets"}
                          />{" "}
                          Faucets
                        </label>
                      </td>

                      <td>{row.brand}</td>
                      <td>{row.product_desc}</td>
                      <td>{row.finish}</td>

                      <td>{row.collection}</td>

                      <td> {row.Group}</td>
                      <td className="w-24 h-24">
                        {" "}
                        <img src={row.image} />
                      </td>
                      <td>
                        <input
                          className="px-1 py-1 text-gray-700 border rounded-md w-16"
                          type="number"
                          id=""
                          name="qty"
                          value={row.quantity}
                          onChange={(e) => changeformdataqty(e, row.id)}
                          size="3"
                        ></input>
                      </td>

                      <td>{row.mrp}</td>
                      <td>{row.unit ? formatNumber(row.unit) : ""}</td>
                      <td className="">
                        <input
                          className="px-1 py-1  text-gray-700 border rounded-md min-w-[5.2rem]"
                          type="number"
                          id=""
                          name=""
                          disabled={amountType === "price" ? false : true}
                          onChange={(e) => changeAmountType(e, row.id)}
                          size="3"
                          value={formatNumber(row.discount_price)}
                        ></input>
                      </td>
                      <td className="">
                        <input
                          className="px-1 py-1 text-gray-700 border rounded-md w-16"
                          type="number"
                          id=""
                          name=""
                          disabled={amountType == "percentage" ? false : true}
                          onChange={(e) => changeAmountType(e, row.id)}
                          value={formatNumber(row.discount_per)}
                          size="3"
                        ></input>
                      </td>
                      <td>{gst ? row.gst : 0}</td>
                      {/* <td>{row.total ? row.total.toFixed(2) : ""}</td> */}
                      <td>{row.total ? formatNumber(row.total) : 0}</td>
                      {/* <td>{row.selection}</td> */}
                    </tr>
                  );
                }
              })}
          </tbody>
          <tbody className="bg-white/80">
            <td colSpan={10} className="font-bold">
              Total
            </td>

            <td className="font-bold">
              {" "}
              {total_quantity ? formatNumber(total_quantity) : 0}
            </td>
            <td></td>
            <td></td>
            <td className="font-bold">{formatNumber(total_discount_price)}</td>

            <td className="text-left font-semibold border border-slate-600"></td>
            <td className="text-left font-semibold border border-slate-600"></td>
            <td className="font-bold">
              {total_amount ? formatNumber(total_amount) : 0}
            </td>
          </tbody>
        </table>

        {/* <div className="flex justify-center gap-x-5 py-4">
          <button
            className="bg-blue-950 text-white p-2 rounded w-32	"
            onClick={() => addRow(null)}
          >
            Add Row
          </button>
          <button
            className="bg-blue-950 text-white p-2 rounded w-32	"
            onClick={(e) => removeRow(e)}
          >
            Delete Rows
          </button>
          <button
            className="bg-blue-950 text-white p-2 rounded w-32	"
            onClick={(e) => resetState(e)}
          >
            Reset{" "}
          </button>
          <button
            onClick={saveProjectData}
            className="bg-blue-950 text-white p-2 rounded w-32"
          >
            {projectId ? "Update" : "Save"}
          </button>
        </div> */}
      </main>
    </>
  );
};

export default UserProjects;
