import React from "react";
import { ReactTyped } from 'react-typed';
import icon from "./icon/I1.png"; // Assuming the image is correctly placed in the "icon" folder

const SmartSearchButton = () => {
    return (
        <div
            className="relative flex items-center justify-between w-40 h-14 bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white font-semibold rounded-full cursor-pointer transition-all duration-500 hover:scale-110 hover:shadow-2xl hover:ring-2 hover:ring-blue-400"
        >
            {/* Typing Effect */}
            <ReactTyped
                strings={["SMART FILTER", "COLLECTION", "FINSHES", "TYPE", "BASIN MIXER", "ALLURE", "GROHE", "CHROME", "MATT", "BATH MIXER"]}
                typeSpeed={100}
                backSpeed={50}
                backDelay={1000}
                startDelay={500}
                loop={true}
                className="text-white text-xs  font-medium tracking-tighter pl-6"
            />

            {/* Image Icon */}
            <div className="flex items-center justify-center w-12 h-12 bg-gradient-to-r from-blue-600 to-teal-500 rounded-full shadow-xl transform transition-transform duration-300 ease-in-out hover:scale-125 hover:bg-blue-500">
                <img src={icon} alt="Search Icon" className="w-12 rounded-full h-12 object-contain" />
            </div>

            {/* Glowing Border */}
            <div className="absolute inset-0 border-2 border-transparent rounded-full  bg-gradient-to-r from-blue-500 via-teal-500 to-gray-500 opacity-20"></div>
        </div>
    );
};

export default SmartSearchButton;
