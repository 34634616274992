import React, { useState, useEffect, useRef } from "react";

import { Link, useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa"; // Logout icon
import { gsap } from "gsap";
import client from "../../../src/homeIcons/client.png";
import helpdesk from "../../../src/homeIcons/helpdesk.png";
import quote from "../../../src/homeIcons/quote.png";
import summary from "../../../src/homeIcons/summary.png";
import grohe from "../../../src/clientlogo/index_grohe_logo.jpg";
import americanStandard from "../../../src/clientlogo/index_as_logo.gif";
import lixil from "../../../src/clientlogo/index_lixil_logo.gif";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import img1 from "../../../src/SliderImage/1.jpg";
import img3 from "../../../src/SliderImage/3.jpg";
import img4 from "../../../src/SliderImage/4.jpg";
import img5 from "../../../src/SliderImage/5.jpg";
import img6 from "../../../src/SliderImage/6.jpg";
import img7 from "../../../src/SliderImage/7.jpg";
import img8 from "../../../src/SliderImage/8.jpg";
import Carousel from "../Carousel";
import Logo from "../utils/Logo";
const UserHomepage = () => {
  // const images = [
  //   "https://grohelogin.ruminateitsolution.com/4N.jpg",
  //   "https://grohelogin.ruminateitsolution.com/3N.jpg",
  //   "https://grohelogin.ruminateitsolution.com/6N.jpg",
  // ];

  const images = [img1, img3, img4, img5, img6, img7, img8];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const clientLogos = [
    { src: grohe, alt: "Grohe" },
    { src: americanStandard, alt: "American Standard" },
    { src: lixil, alt: "Lixil" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // GSAP
  useEffect(() => {
    gsap.fromTo(
      ".icon-link",
      { scale: 0.8, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.8, stagger: 0.2, ease: "power2.out" }
    );

    gsap.fromTo(
      ".client-logo",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, stagger: 0.2, ease: "power2.out" }
    );
  }, []);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("user");
    // window.location.href = "/";
    navigate("/");
  };

  return (
    <main className="w-full h-screen  overflow-hidden">
      {/* Full-Screen Slider */}
      {/* <Carousel images={images} /> */}

      {/* Icons Over Slider */}
      <div className="absolute inset-0 flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mx-4">
          {/* Add/View Client */}
          <Link
            to="/user/viewclient"
            className="icon-link flex flex-col justify-center items-center bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-500 transition-all duration-300 transform hover:scale-105 p-6 rounded-lg shadow-xl"
          >
            <img
              src={client}
              alt="Add/View Client"
              className="w-12 h-12 mb-2"
            />
            <span className="text-white text-sm font-medium">
              Add/View Client
            </span>
          </Link>

          {/* Create Quotation */}
          <Link
            to="/user/projects"
            className="icon-link flex flex-col justify-center items-center bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-500 transition-all duration-300 transform hover:scale-105 p-6 rounded-lg shadow-xl"
          >
            <img
              src={quote}
              alt="Create Quotation"
              className="w-12 h-12 mb-2"
            />
            <span className="text-white text-sm font-medium">
              Create Quotation
            </span>
          </Link>

          {/* Summary */}
          <Link
            to="/user/summary"
            className="icon-link flex flex-col justify-center items-center bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-500 transition-all duration-300 transform hover:scale-105 p-6 rounded-lg shadow-xl"
          >
            <img src={summary} alt="Summary" className="w-12 h-12 mb-2" />
            <span className="text-white text-sm font-medium">Summary</span>
          </Link>

          {/* Help Desk */}
          <Link
            to="/user/help-desk"
            className="icon-link flex flex-col justify-center items-center bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-500 transition-all duration-300 transform hover:scale-105 p-6 rounded-lg shadow-xl"
          >
            <img src={helpdesk} alt="Help Desk" className="w-12 h-12 mb-2" />
            <span className="text-white text-sm font-medium">Help Desk</span>
          </Link>
        </div>

        {/* Logout and Username */}
        <div className="absolute top-8 right-4 flex items-center gap-2">
          <span className="text-white text-lg uppercase">
            Hey
            {" " + localStorage.getItem("user_name")}
          </span>
          <WavingHandIcon className="text-yellow-200 animate-bounce hover:animate-none " />
          <Tooltip title="LOG-OUT" arrow>
            <IconButton color="inherit" onClick={handleLogout}>
              <FaSignOutAlt
                size={24}
                className="text-white hover:text-yellow-500 hover:scale-95"
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {/* Client Logos Section */}
      <div className="fixed top-4 left-4 p-4  rounded-lg shadow-lg border border-gray-200 pointer-events-none">
        {/* First Row: Two Images */}
        <div className="flex justify-between gap-4 mb-3">
          {clientLogos.slice(0, 2).map((logo, index) => (
            <div
              key={index}
              className="p-2 "
              style={{
                backgroundColor: index === 0 ? "#111F39" : "#fff",
              }}
            >
              <img src={logo.src} alt={logo.alt} className="w-16 h-8" />
            </div>
          ))}
        </div>

        {/* Divider */}
        <hr className="border-t border-gray-200 mb-3" />

        {/* Third Image and Text */}
        <div className="flex justify-end items-center">
          <p className="text-white text-xs font-semibold">PART OF</p>
          <div className="p-2 ">
            {/* <img
              src={clientLogos[2].src}
              alt={clientLogos[2].alt}
              className="w-9 h-4 "
            /> */}
            <p className="text-orange-500 bg-white p-1 text-xs font-semibold">
              LIXIL
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserHomepage;
