import { useEffect, useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import { Link } from "react-router-dom";
import UserTopbar from "../UserTopbar";
import animationData from "../../animations/loader_animation0.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { FaDownload, FaSearch } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import DownloadSummary from "./DownloadSummary";

import ViewProjects from "./ViewProjects";
import Loader from "../utils/Loader";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";
import { useOutletContext } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TablePagination,
  Box,
} from "@mui/material";
// import { handleApiError } from "../../Helpers/handleApiError ";

const Summary = () => {
  const navigate = useNavigate();
  const [sectionArr, setSectionArr] = useState([]);
  const [projectId, setprojectId] = useState();
  const [pdfId, setpdfId] = useState();
  const [projectDatas, setprojectData] = useState();
  const [sectionTotal, setSectionTotal] = useState(0);
  const [showComponent, setshowComponent] = useState(false);
  const [showPdfComponent, setshowPdfComponent] = useState(false);
  const [pdfloading, setPdfLoading] = useState(false);
  const [excelloading, setExcelLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { isSidebarOpen } = useOutletContext();

  const handleDataFromDownloadSummary = (data) => {
    console.log("data from child" + data);
    setDataFromDownloadSummary(data);
  };

  useEffect(() => {
    if (
      dataFromDownloadSummary == "downloaded" ||
      dataFromDownloadSummary == "failed"
    ) {
      setExcelLoading(false);
    }
  }, [dataFromDownloadSummary]);

  useEffect(() => {
    projectData();
  }, []);
  useEffect(() => {
    // Set loading to true when component mounts
    setLoading(true);

    // Set loading to false after one minute
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 60000 milliseconds = 1 minute

    // Clear the timer when the component unmounts or the dependency changes
    return () => clearTimeout(timer);
  }, []);
  const [project, setProject] = useState([
    {
      user_id: "",
      client_id: "",
      finish: "",
      area: "",
      total: "",
      project_id: "",
    },
  ]);
  useEffect(() => {
    if (showComponent) {
      setLoading(true); // Show loading message when component is shown
      // Simulate loading delay (remove setTimeout in real use case)
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Example: Simulating a 2-second loading time
    }
  }, [showComponent]);

  const projectData = async () => {
    try {
      setLoading(true);
      const response = await UserSection.projectData();
      if (response.status) {
        // console.log(response);
        setProject(response.data);
        // console.log(project);
      }
      setLoading(false);
    } catch (error) {
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
    }
  };
  const editProject = (id) => {
    navigate(`/user/projects/${id}`);
  };
  const downloadexcel = async (id) => {
    try {
      //   setExcelLoading(true);
      setprojectId(id);
      //   setTimeout(() => {
      //     setExcelLoading(false);
      //   }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadpdf = async (id) => {
    try {
      navigate(`/user/viewProjects?project_id=${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredData = project.filter(
    (client) =>
      client.client_name?.toLowerCase().includes(searchQuery) ||
      String(client.project_id).toLowerCase().includes(searchQuery) ||
      String(client.client_id).toLowerCase().includes(searchQuery)
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedProjects = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <UserTopbar heading={`Project Summary`} sidebar={isSidebarOpen} />
      {loading ? (
        <div class="loder">{<Loader />}</div>
      ) : (
        <>
          <Box
            className="bg-gray-900/90 p-3 "
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              borderColor: "#fff",
            }}
          >
            <div className=" relative">
              <input
                type="text"
                onChange={handleSearchChange}
                placeholder="Search by Project-Id Client-Id Client-Name... "
                className="p-3 w-[27rem] border-2 border-teal-800 rounded-md text-black  transition duration-200"
                required
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FaSearch className="text-teal-800" />
              </span>
            </div>
          </Box>
          <TableContainer>
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Download Sheet
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Preview
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Project ID
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Client ID
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                   Valid Date
                  </TableCell>

                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                {paginatedProjects.map((row) => (
                  <TableRow key={row.id}>
                    {/* Download */}
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {excelloading && <Loader />}
                        <IconButton
                          color="primary"
                          onClick={() => {
                            downloadexcel(row.project_id);

                            setExcelLoading(true);
                          }}
                        >
                          <DownloadIcon className="cursor-pointer hover:scale-125" />
                        </IconButton>
                      </div>
                    </TableCell>

                    {/* Project ID */}
                    <TableCell>
                      {pdfloading && row.project_id === pdfId ? (
                        <Lottie
                          animationData={animationData}
                          loop
                          autoplay
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        <IconButton
                          color="error"
                          onClick={() => {
                            downloadpdf(row.project_id);
                            localStorage.setItem("projectId", row.project_id);
                          }}
                        >
                          <PreviewIcon className="cursor-pointer hover:scale-125" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>{row.project_id}</TableCell>

                    {/* Client ID */}
                    <TableCell>{row.client_id}</TableCell>

                    {/* Client Name */}
                    <TableCell>{row.client_name}</TableCell>

                    {/* Date */}
                    <TableCell>
                      {new Date(row.created_at).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell>
                      {new Date(row.valid_date).toLocaleDateString("en-GB")}
                    </TableCell>
                    {/* Action */}
                    <TableCell>
                      <Button
                        variant="contained"
                        // color="primary"
                        onClick={() => editProject(row.id)}
                        style={{
                          textTransform: "none",
                          backgroundColor: "teal",
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
            rowsPerPageOptions={[10, 50, 100, 500, filteredData.length]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <div style={{ display: showComponent ? "block" : "none" }}>
        <DownloadSummary
          project={projectId}
          sendData={handleDataFromDownloadSummary}
        />
      </div>
      {/* <div style={{ display: showComponent ? 'block' : 'none' }}>
        <ViewProjects project={pdfId} />
      </div> */}
    </>
  );
};

export default Summary;
