import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import UserSection from "../../Services/User/UserLogin";
import { handleApiError } from "../../Helpers/handleApiError ";
import Swal from "sweetalert2";
function UserForgotPassword() {
  const [email, setEmail] = useState();
  const [button, setButton] = useState('Send');
  const [disable, setDisable] = useState(false);
  const forgotpassword = async (e) => {
    setDisable(true);
    setButton('Please wait...');
    e.preventDefault();
    try {
      const user = localStorage.getItem("user");
      const headers = {
        'Content-Type': 'application/json', // Example header
        Authorization: 'Bearer ' + user, // Example Authorization header
      };

      const response = await axios.post('https://quatationsapis.groheapp.in/public/api/forgot-password', { email, headers });
      if (response.status) {
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: response.data.message,
          timer: 10000, // Automatically close after 3 seconds
          showConfirmButton: true,
        })
          .then((result) => {
            if (result.isConfirmed) {
              setButton('Send');
              setDisable(false);
              window.location.href = '/';
            }
          });

      }

    } catch (error) {
      setButton('Send');
      setDisable(false);
      handleApiError(error, 'Failed to fetch data. Please try again later.');

    }

  }
  return (
    <>
      <div className="flex">
        <div className="flex-1">

          <img src="/forgotPassword.jpg" alt="" width="100%" />
        </div>

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex justify-evenly">
              <img
                className="mx-auto h-10 w-auto"
                src="/lixil.png"
                alt="Your Company"
              />
              <img
                className="mx-auto h-10 w-auto"
                src="/grohe.png"
                alt="Your Company"
              />
              <img
                className="mx-auto h-10 w-auto"
                src="/american.png"
                alt="Your Company"
              />

            </div>
            <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Forgot your account password
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6"  >
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <button onClick={forgotpassword}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={disable}
                >
                  {button}
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Already a member?{' '}

              <Link to='/' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserForgotPassword;